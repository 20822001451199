"use client";

import { Form, Formik, type FormikHelpers } from "formik";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";

import {
  DEFAULT_SECONDARY_OFFERS_STEPS,
  EVENT_LEAD_FORM_SUBMITTED,
  EVENT_STEP_SUBMITTED,
  LEAD_FORM_FLOW,
  LEAD_FORM_SUBMIT,
} from "@shared/constants";

import getLeadSubmission from "@server/front-end-api/getLeadSubmission";
import patchLeadSubmissionDraft from "@server/front-end-api/patchLeadSubmissionDraft";
import postEvent from "@server/front-end-api/postEvent";
import postLeadSubmission from "@server/front-end-api/postLeadSubmission";
import postTrustedForm from "@server/front-end-api/postTrustedForm";
import getSecondaryOfferFlowEnabled from "@server/launch-darkly/getSecondaryOfferFlowEnabled";

import DataLayer from "@client/classes/data-layer/data-layer";
import LocalStorage from "@client/classes/data-layer/local-storage";

import type LeadForm from "@packages/types/lead-form";
import { validationSchema } from "@packages/types/yup/lead-form";
import type { DefaultFieldValues } from "@packages/types/yup/lead-form";

/**
 * Note: This is a "client" component because Formik creates a React context and that's something you can only do
 *       client side.
 *
 * Note: `initialValues` gets defined "server-side", and gets passed to this component on the "client-side" so we
 *       can use LocalStorage to get/save field values. However, we don't want to save `step`, `stepName`, or
 *       `submitAction` in LocalStorage, because these values are specific to each individual step and we don't
 *       ever want to accidentally overwrite them "client-side".
 *
 *       On render, we loop through `initalValues` and replace the "default" values with "saved" values. We don't loop
 *       through LocalStorage because it can contain fields that don't belong to this step. Formik doesn't care
 *       if a field exists or not, it sends what's inside `initialValues` so please be careful when modifying
 *       `initalValues`.
 *
 * @see https://formik.org/docs/api/formik#initialvalues-values
 */

type Props = {
  children: React.ReactNode;
  initialValues: LeadForm.StepValues;
  journey: string;
  redirectPath: string;
  step: number;
  stepName: string;
  submitMethod: LeadForm.SubmitMethod;
};

/**
 * Push Trusted Form fields to Front-End API
 *
 * Note: We do this by reading the field values directly from the Lead Form using a React `useRef` hook. Because
 *       Trusted Form dynamically injects the fields into the Form.
 */
async function postTrustedFormValues(form: HTMLFormElement) {
  if (form.xxTrustedFormCertUrl || form.xxTrustedFormPingUrl) {
    postTrustedForm(
      form.xxTrustedFormCertUrl.value,
      form.xxTrustedFormPingUrl.value,
    );
  }
}

/**
 * Submit Lead to Front-End API
 */
export async function submitForm(
  redirectPath: string,
  values: LeadForm.StepValues,
) {
  let provider;

  // allow cypress to target this server action
  values.cypress = "lead-form";

  // wait for Lead Form submission to complete
  let { data, error } = await postLeadSubmission(values);

  // When the user tries to submit the same form twice, the second submission will fail and return an error
  // If so, then we need to fetch the existing Lead Submission data
  // TODO: refactor for better error handling than just matching the error message
  if (error === "LeadSubmissionDraft has already been submitted") {
    // Use the existing Lead Submission data
    const response = await getLeadSubmission();

    if (response.error) {
      throw new Error(response.error?.toString());
    }
  }

  if (error) {
    throw new Error(error);
  }

  if (!data?.lead_submission?.data?.offers[0]?.provider) {
    throw Error("Lead Submission did not return a provider.");
  }

  // redirect user to correct "thank you" page
  switch (data?.lead_submission?.data?.offers[0]?.provider) {
    case "beyond_finance":
      provider = "accredited";
      break;
    case "credible":
      provider = "credible";
      break;
    case "even_finance":
      provider = "fiona";
      break;
    default:
      provider = "credible";
      break;
  }

  // clear LocalStorage once Lead Form has been submitted
  LocalStorage.delete();

  const secondaryOfferFlowEnabled =
    provider === "fiona" &&
    (await getSecondaryOfferFlowEnabled({
      us_state: data?.lead_submission_draft?.data?.state,
    }));

  // trigger "lead form submitted" event
  DataLayer.events.trigger(EVENT_LEAD_FORM_SUBMITTED, {
    ...data?.ad_platform_signals,
    secondaryOfferFlowEnabled,
  });

  if (secondaryOfferFlowEnabled) {
    return redirectPath.replace(
      "/thank-you",
      `/offers/steps/${DEFAULT_SECONDARY_OFFERS_STEPS[0].name}`,
    );
  }

  if (!redirectPath.includes(provider)) {
    redirectPath += `/${provider}`;
  }

  return redirectPath;
}

/**
 * Submit Step to Front-End API
 */
async function submitStep(values: LeadForm.StepValues) {
  // allow cypress to target this server action
  values.cypress = "lead-form";

  // DON'T wait for Step submission to complete
  // NOTE: this needs to be fired before the `EVENT_STEP_SUBMITTED` event is triggered
  await patchLeadSubmissionDraft(values);

  // trigger "step submitted" event
  // NOTE: this needs to be triggered after patchLeadSubmissionDraft
  DataLayer.events.trigger(EVENT_STEP_SUBMITTED, {
    ...values,
    flow: LEAD_FORM_FLOW,
  });
}

/**
 * Save Lead Form field values to Local Storage
 *
 * Note: We don't save `step`, `stepName`, or `submitMethod` to prevent accidentally overwriting values.
 */
function updateLocalStorage(values: LeadForm.StepValues) {
  const fields = { ...values } as any;
  delete fields.step;
  delete fields.stepName;
  delete fields.submitMethod;

  // attempt to save form values to local storage
  LocalStorage.update(fields);
}

export default function LeadFormFormik({
  children,
  initialValues,
  journey,
  redirectPath,
  step,
  stepName,
  submitMethod,
  ...props
}: Props) {
  const [submitAttempts, setSubmitAttempts] = useState(0);
  const router = useRouter();

  const formRef = useRef(null);

  // get "saved values" from LocalStorage (if available)
  let savedValues = LocalStorage.read() || {};

  // replace "initial values" with "saved values" (if applicable)
  Object.keys(initialValues).forEach((key) => {
    if (savedValues.hasOwnProperty(key)) {
      initialValues[key] = savedValues[key];
    }
  });

  const handleSubmit = async (
    values: LeadForm.StepValues,
    formikHelpers: FormikHelpers<LeadForm.StepValues>,
  ) => {
    try {
      if (stepName === "email" && submitAttempts === 0) {
        if (document.querySelector(".invalid-email")) {
          setSubmitAttempts(submitAttempts + 1);
          formikHelpers.setStatus("warning");
          return;
        }
      }
      // save field values to LocalStorage
      updateLocalStorage(values);

      // set Trusted Form fields (if applicable)
      await postTrustedFormValues(
        formRef.current as unknown as HTMLFormElement,
      );

      // Don't show an error or warning state if step is submitting
      formikHelpers.setStatus("");

      // handle form submission
      if (values.submitMethod === LEAD_FORM_SUBMIT) {
        // trigger "step submitted" event
        // Note: this needs to be triggered before postLeadSubmission
        DataLayer.events.trigger(EVENT_STEP_SUBMITTED, {
          ...values,
          flow: LEAD_FORM_FLOW,
        });

        LocalStorage.update({ lastStepValues: JSON.stringify(values) });
        LocalStorage.update({ redirectPath: redirectPath });

        // Redirect user to loading page where we will submit the form
        router.push(`/${journey}/loading`);
      } else {
        await submitStep(values);
        // move user to next step
        router.push(redirectPath);
      }
    } catch (e) {
      postEvent("error", {
        error: {
          error_message: `An error occurred while attempting to submit the ${stepName} step.`,
          error_object: "Lead Form",
          error_type: "Front-End API",
          path: window.location.href,
        },
      });
      formikHelpers.setStatus("error");
    }
  };

  useEffect(() => {
    if (submitMethod !== "submit" && redirectPath) {
      router.prefetch(redirectPath);
    }
  }, [redirectPath, router, submitMethod]);
  return (
    <Formik
      {...props}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema[stepName as keyof DefaultFieldValues]}
    >
      <Form
        className="form"
        ref={formRef}
        noValidate={true}
        data-spec-lead-form
        method="POST"
        action=""
      >
        <input type="hidden" name="step" value={step} />
        <input type="hidden" name="stepName" value={stepName} />
        {children}
      </Form>
    </Formik>
  );
}
